<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">({{customerDetail.phone_number}})Customer Loyalty Activities</h4>
                  <div class="">
                    <div class="btn-toolbar" v-if="adminRoles.includes(user.role.id)">
                    </div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Amount (Earned/Redeemed)</th>
                        <th scope="col">Point (Earned/Redeemed)</th>
                        <th scope="col">Branch</th>
                        <th scope="col">Activity Type</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading && activities.length > 0">
                      <tr v-for="activity in activities" :key="activity.id">
                        <td>{{currency +' '+ formatPrice(activity.amount)}}</td>
                        <td>{{activity.points}}</td>
                        <td>{{activity.brand}}</td>
                        <td :style="activity.activity_type == 'EARNING' ? 'color:#1A8B1F' : 'color:#A81414'">{{activity.activity_type}}</td>
                        <td>{{formatDate(activity.created_at)}}</td>
                        <!-- <td></td> -->
                      </tr>
                    </tbody>
                    <tbody v-if=" activities.length  == 0">
                      <tr>
                        <td colspan="7" style="text-align:center"> No Data</td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
        
                            </ul>
                          </div>
        
                        </td>
                      </tr>
                    </tfoot>
                  </table>
        
                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
        
        <script>
        import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
        import ReusableDataMixins from '@/mixins/ReusableDataMixins';
        import Pagination from '@/pages/pagination/pagination.vue';
        export default {
            components: {
                Pagination,
            },
            data() {
                return {
                    customerDetail:[],
                    activities: [],
                    Id: this.$route.params.id,
                    loading: false,
                    search:'',
                    page: 1,
                    start: '',
                    end: '',
                    totalPages: 0,
                    totalRecords: 0,
                    recordsPerPage: 10,
                    debounce: null,
                    menuspinner: "<i class='fa fa-spinner fa-spin '></i>",
                    currency: ''
                }
            },
            mounted: async function () {
                this.getLoyaltyActivities();
                this.currency = this.pesapalConfig.currency_code ?  this.pesapalConfig.currency_code : 'KES';
                // disable input if use is not admin or superadmin
        
            },
            methods: {
              searchActivity() {
                    clearTimeout(this.debounce);
                    this.debounce = setTimeout(() => {
                        this.getLoyaltyActivities();
                    }, 1500)
                },
                async getLoyaltyActivities() {
                    this.loading = true;
                    let url = `${this.dashBaseUrl}/loyalty-activity-detail/${this.Id}`;
                    url = new URL(url);
                    url.searchParams.set('page', this.page);
                    url.searchParams.set('size', this.recordsPerPage);
                    url.searchParams.set('merchant_id', this.merchantID);
                    const config = {
                        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                    };
                    this.axios.get(url, config).then((response) => {
                        if (response.status === 200) {
                            this.activities = response.data.data.data
                            if(this.activities.length > 0){
                              this.customerDetail = this.activities[0]
                            }
                            this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                            this.totalRecords = response.data.data.total_count
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                        this.searchLoading = '';
                    })
                },
                onPageChange(page) {
                    this.page = page
                    this.getLoyaltyActivities();
                },
                onChangeRecordsPerPage() {
                    this.getLoyaltyActivities();
                },
            },
            mixins: [ReusableFunctionMixins, ReusableDataMixins]
        }
        </script>
        